@tailwind base;
@tailwind components;
@tailwind utilities;

.flex-row-view {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  box-sizing: border-box;
  flex-basis: auto;
}

.flex-col-view {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  flex-basis: auto;
}

.flex-row-center {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: border-box;
  flex-basis: auto;
}

.flex-col-center {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: border-box;
  flex-basis: auto;
}

.title-text {
  user-select: none;
  font-family: "OtomanopeeOne-Regular";
  letter-spacing: 0;
  font-size: 20px;
  line-height: normal;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  white-space: pre-line;
  box-sizing: border-box;
  flex-shrink: 0;
}

.text {
  user-select: none;
  font-family: "MPLUSRounded1c-Bold";
  letter-spacing: 0;
  font-size: 20px;
  line-height: normal;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  white-space: pre-line;
  box-sizing: border-box;
  flex-shrink: 0;
}

.shadow-button {
  cursor: pointer;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: border-box;
  flex-basis: auto;
  border-radius: 10px;
  padding: 0;
  box-shadow: inset 0 -6px 0px rgba(63, 74, 97, 0.4);
}

.shadow-button:enabled:active {
  box-shadow: inset 0 -2px 0px rgba(63, 74, 97, 0.4);
  bottom: -4px;
  opacity: 0.7;
}

.shadow-button:disabled {
  box-shadow: inset 0 -6px 0px rgba(63, 74, 97, 0.4);
  opacity: 0.3;
}

.highlight-button {
  cursor: pointer;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: border-box;
  flex-basis: auto;
  border-radius: 10;
  padding: 0;
  background-color: transparent;
}

.highlight-button:enabled:active {
  background-color: rgb(0, 0, 0);
}

.highlight-button:disabled {
  background-color: rgb(0, 0, 0);
}

.opacity-button {
  cursor: pointer;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: border-box;
  flex-basis: auto;
  border-radius: 10;
  padding: 0;
  background-color: transparent;
}

.opacity-button:enabled:active {
  opacity: 0.7;
}

.opacity-button:disabled {
  opacity: 0.7;
}
